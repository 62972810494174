import React, {useState, useEffect} from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import MembershipListItem from "./item";
import {getMembershipsForLocations, getMembershipsById} from "../../../redux/purchasables/actions";

import './index.css';

const MembershipList = ({locationIds, activePurchasableIds, activePurchasables}) => {
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState(activePurchasables);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (locationIds) {
            setLoading(true);
            dispatch(getMembershipsForLocations(locationIds, setLoading, setRecords, enqueueSnackbar));
        } else if (activePurchasableIds) {
            setLoading(true);
            dispatch(getMembershipsById(activePurchasableIds, setLoading, setRecords, enqueueSnackbar));
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {loading || !records ?
                <CircularProgress size={45} />
                :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                Purchaser
                            </TableCell>
                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                Location
                            </TableCell>
                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                Location Contacts
                            </TableCell>
                            <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                Org Contacts
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((record, index) =>  (
                            <TableRow key={index}>
                                <MembershipListItem activePurchasable={record} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>


            }
        </div>
    )
};

export default MembershipList;
