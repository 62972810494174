import React from 'react';
import { Redirect } from 'react-router-dom';

import {useLoginSelector} from "../../../redux/login/selectors";

const AdminOnly = ({ children, noRedirect }) => {

    const { claims } = useLoginSelector();
    const { admin } = claims || {};

    return (
        admin ?
            children
            :
            (!noRedirect ? <Redirect to="/location-report" /> : null)
    )

};

export default AdminOnly;