import _ from 'lodash';

import {useShallowEqualSelector} from "../index";
import attributeIds from "./attributeIds";

import {
    ATTRIBUTES_LOADING,
    ATTRIBUTES_SUCCESS,
    ATTRIBUTES_ERROR
} from "./types";

const INITIAL_STATE = {
    loading: true,
    attributeIds: null,
    attributeMap: {},
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ATTRIBUTES_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };
        case ATTRIBUTES_SUCCESS:
            return {
                ...state,
                loading: false,
                attributeIds: action.payload.map(attribute => attribute.key),
                attributeMap: action.payload.reduce((map, attribute) => {
                    map[attribute.key] = attribute;
                    return map;
                }, {...state.attributeMap}),
            };
        case ATTRIBUTES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

/**
 * Returns the data necessary to render the attribute list
 */
export const useAttributeListSelector = () => {
    return useShallowEqualSelector(({ attributes }) => {
        const attributeList = attributes.attributeIds ? attributes.attributeIds.map(id => attributes.attributeMap[id]) : null;
        return {
            ...(_.pick(attributes, ["loading", "attributeIds", "error"])),
            attributeList
        };
    });
};

export const useAttributeInflatorSelector = () => {
    return useShallowEqualSelector(({ attributes }) => {
        return (attributes.attributeIds && attributes.attributeIds.map(id => attributes.attributeMap[id])) || [];
    });
}

/**
 * Return a single Location record
 * @param attributeId    id of the attribute to return
 */
export const useAttributeSelector = (attributeId) => {
    return useShallowEqualSelector(({ attributes }) => {
        return attributes.attributeMap[attributeId] ? attributes.attributeMap[attributeId] : null;
    });
};

export const useMedicationsAvailableSelector = () => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        if (attributeMap[attributeIds.medicationsAvailable]) {
            const { groups } = attributeMap[attributeIds.medicationsAvailable];
            return Object
                .keys(groups)
                .map(groupId => {
                    const options = groups[groupId].options;
                    return Object.keys(options).map(optionId => ({ id: optionId, name: options[optionId].name }))
                })
                .reduce((result, groupOptions) => [...result, ...groupOptions], [])
                .sort((item1, item2) => item1.name.localeCompare(item2.name))
        }
        return null;
    });
};

export const useSpecialtiesSelector = () => {
    return useShallowEqualSelector(({ attributes: { attributeMap } }) => {
        if (attributeMap[attributeIds.practiceSpecialty]) {
            const { options } = attributeMap[attributeIds.practiceSpecialty];
            return Object
                .keys(options)
                .map(optionId => ({ id: optionId, name: options[optionId].name }))
                .sort((item1, item2) => item1.name.localeCompare(item2.name))
        }
        return null;
    });
};

export default reducer;