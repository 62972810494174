import {
    MEMBERSHIP_REPORT_LOADING,
    MEMBERSHIP_REPORT_SUCCESS,
    MEMBERSHIP_REPORT_ERROR
} from "./types";

export const loadMembershipReport = (enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch({ type: MEMBERSHIP_REPORT_LOADING });
        return api.getMembershipsReport()
            .then(report => {
                console.log("MembershipReport", report);
                return dispatch({ type: MEMBERSHIP_REPORT_SUCCESS, payload: report });
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch({ type: MEMBERSHIP_REPORT_ERROR });
            })
    }
);

export const loadPurchasablesReport = (enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch({ type: MEMBERSHIP_REPORT_LOADING });
        return api.getMembershipsReport()
            .then(report => {
                console.log("MembershipReport", report);
                return dispatch({ type: MEMBERSHIP_REPORT_SUCCESS, payload: report });
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch({ type: MEMBERSHIP_REPORT_ERROR });
            })
    }
);