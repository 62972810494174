import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, subWeeks, format } from 'date-fns';

import GoogleHeatMap from "../_common/googleHeatMap";
import Loading from "../_common/loading";
import {loadAttributes} from "../../redux/attributes/actions";
import {useAttributeListSelector, useSpecialtiesSelector, useMedicationsAvailableSelector} from "../../redux/attributes";
import {fetchAdminReport} from "../../redux/queries/actions";
import {useQueriesSelector} from "../../redux/queries/selectors";
import PlacesSearch from "../_common/placesSearch";
import {distanceInMiles} from "../../utils/meters";
import {reportsRestrictionsSelector} from "../../redux/login/selectors";
import useFetchReportsRestrictions from "../../hooks/useFetchReportsRestrictions";

import './index.css';

const AdminQueryReport = () => {
    const [map, setMap] = useState(null);
    const [radius, setRadius] = useState(100);
    const [search, setSearch] = useState(null);
    const [specialtyIds, setSpecialtyIds] = useState({});
    const [medicationIds, setMedicationIds] = useState({});
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
    const [endDate, setEndDate] = useState(addDays(new Date(), 1));
    const [hideSearchesWithoutSpecialty, setHideSearchesWithoutSpecialty] = useState(false);
    const [hideSearchesWithoutMedication, setHideSearchesWithoutMedication] = useState(false);
    const {loading: attributesLoading} = useAttributeListSelector();
    const specialties = useSpecialtiesSelector();
    const medications = useMedicationsAvailableSelector();
    const {loading: queriesLoading, results, specialtyQueries, medicationQueries, resultsCount, specialtyQueryIds, medicationQueryIds} = useQueriesSelector();
    const dispatch = useDispatch();
    const reportsRestrictions = useSelector(reportsRestrictionsSelector);
    useFetchReportsRestrictions();

    useEffect(() => {
        dispatch(loadAttributes());
        dispatch(fetchAdminReport(_formatDate(startDate), _formatDate(endDate)));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (results) {
            setSpecialtyIds(Object.keys(specialtyQueries).reduce((ret, specialtyId) => {
                ret[specialtyId] = true;
                return ret;
            }, {}));
            if (reportsRestrictions) {
                setMedicationIds({[reportsRestrictions.medicationIds]: true})
            } else {
                setMedicationIds(Object.keys(medicationQueries).reduce((ret, medicationId) => {
                    ret[medicationId] = true;
                    return ret;
                }, {}));
            }
        }
    }, [resultsCount, reportsRestrictions]); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredResults = useMemo(() => {
        if (results && (_hasTrueValue(specialtyIds) || _hasTrueValue(medicationIds))) {
            const idMap = {};

            console.log("specialtyIds", specialtyIds, "medicationIds", medicationIds);

            // First, go through selected SpecialtyIds and add all the specialty queries to the returned map
            Object.keys(specialtyIds).forEach(specialtyId => {
                if (specialtyQueries[specialtyId]) {
                    specialtyQueries[specialtyId].forEach(queryId => {
                        idMap[queryId] = idMap[queryId] || specialtyIds[specialtyId];
                    });
                }
            });
            Object.keys(medicationIds).forEach(medicationId => {
                if (medicationQueries[medicationId]) {
                    medicationQueries[medicationId].forEach(queryId => {
                        idMap[queryId] = idMap[queryId] || medicationIds[medicationId];
                    });
                }
            });
            return results.filter(query => {
                if (hideSearchesWithoutSpecialty && !specialtyQueryIds[query.qid]) {
                    return false;
                }
                if (hideSearchesWithoutMedication && !medicationQueryIds[query.qid]) {
                    return false;
                }
                if (idMap[query.qid]) {
                    if (search) {
                        return distanceInMiles(search.lat, search.lng, query.lat, query.lng) <= radius;
                    }
                    return true;
                }
                return false;
            });
        }
        return results;
    }, [results, specialtyIds, medicationIds]); // eslint-disable-line react-hooks/exhaustive-deps

    const sortedSpecialties = useMemo(() => {
        if (specialtyQueries) {
            const sortedSpecialties = Object.keys(specialtyQueries)
                .map(specialtyId => {
                    const specialty = _getSpecialtyById(specialtyId, specialties);
                    return {
                        id: specialtyId,
                        numSearches: specialtyQueries[specialtyId].length,
                        ...specialty,
                    };
                })
                .filter(specialty => Boolean(specialty.name))
                .sort((specialty1, specialty2) => specialty1.name.localeCompare(specialty2.name));

            return sortedSpecialties;
        }
        return null;
    }, [specialtyQueries, specialties]);

    const sortedMedicationsAvailable = useMemo(() => {
        if (medicationQueries) {
            const sortedMedicationsAvailable = Object.keys(medicationQueries)
                .map(medicationId => {
                    const medication = _getMedicationById(medicationId, medications);
                    return {
                        id: medicationId,
                        numSearches: medicationQueries[medicationId].length,
                        ...medication,
                    };
                })
                .filter(medication => reportsRestrictions && reportsRestrictions.medicationIds ? reportsRestrictions.medicationIds.includes(medication.id) : true)
                .filter(medication => Boolean(medication.name))
                .sort((medication1, medication2) => medication1.name.localeCompare(medication2.name));

            return sortedMedicationsAvailable;
        }
        return null;
    }, [medicationQueries, medications, reportsRestrictions]);

    return (
        <div className="app-page flex flex-col">
            {attributesLoading || !results ?
                <Loading size={45} />
                :
                <div className="flex flex-col flex-1">
                    <div className="admin-query-report-controls">
                        <Card className="mb-5">
                            <CardContent>
                                <div className="flex">
                                    <div>
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="dense"
                                            label="Start Date"
                                            value={startDate}
                                            onChange={date => setStartDate(date)}
                                            autoOk={true}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                    <div className="ml-8">
                                        <DatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="dense"
                                            label="End Date"
                                            value={endDate}
                                            onChange={date => setEndDate(date)}
                                            autoOk={true}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </div>
                                    <div className="ml-8">
                                        <div className="admin-query-report-label">
                                            # Searches
                                        </div>
                                        <div className="mt-3">
                                            {filteredResults.length}
                                        </div>
                                    </div>
                                    <div className="ml-8 flex-1 flex items-center justify-end">
                                        {queriesLoading ?
                                            <CircularProgress size={35} />
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth={false}
                                                onClick={() => { dispatch(fetchAdminReport(_formatDate(startDate), _formatDate(endDate))) }}>
                                                Update
                                            </Button>
                                        }

                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="mb-5">
                            <CardContent>
                                <PlacesSearch map={map} radius={radius} onSearchChange={search => setSearch(search)} onRadiusChange={radius => setRadius(radius)} />
                            </CardContent>
                        </Card>
                        <div className="mt-5">
                            <Accordion disabled={Object.keys(specialtyQueries).length === 0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="specialties">
                                    <div className="flex w-full">
                                        <div className="flex items-center admin-query-report-section-label flex-1">
                                            Specialties
                                        </div>
                                        <div className="ml-5">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="hide"
                                                        color="primary"
                                                        checked={hideSearchesWithoutSpecialty}
                                                        onChange={event => {
                                                            setHideSearchesWithoutSpecialty(event.target.checked);
                                                            event.stopPropagation();
                                                        }}  />
                                                }
                                                label="Hide Searches w/o Specialty"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setSpecialtyIds(Object.keys(specialtyIds).reduce((ret, specialtyId) => {
                                                    ret[specialtyId] = true;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Select All
                                            </Button>
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setSpecialtyIds(Object.keys(specialtyIds).reduce((ret, specialtyId) => {
                                                    ret[specialtyId] = false;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Unselect All
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="admin-location-report-specialties-card">
                                        {sortedSpecialties.map(specialty => (
                                            <div key={specialty.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="specialty"
                                                            color="primary"
                                                            checked={specialtyIds[specialty.id] || false}
                                                            onChange={event => {
                                                                setSpecialtyIds({
                                                                    ...specialtyIds,
                                                                    [specialty.id]: event.target.checked
                                                                });
                                                            }}  />
                                                    }
                                                    label={`${specialty.name} (${specialty.numSearches})`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion disabled={Object.keys(medicationQueries).length === 0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="medicationsAvailable">
                                    <div className="flex w-full">
                                        <div className="flex items-center admin-query-report-section-label flex-1">
                                            Medications Available
                                        </div>
                                        <div className="ml-5">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="hide"
                                                        color="primary"
                                                        checked={hideSearchesWithoutMedication}
                                                        onChange={event => {
                                                            event.stopPropagation();
                                                            setHideSearchesWithoutMedication(event.target.checked)
                                                        }}  />
                                                }
                                                label="Hide Searches w/o Medication"
                                            />
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setMedicationIds(Object.keys(medicationIds).reduce((ret, medicationId) => {
                                                    ret[medicationId] = true;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Select All
                                            </Button>
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setMedicationIds(Object.keys(medicationIds).reduce((ret, medicationId) => {
                                                    ret[medicationId] = false;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Unselect All
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="admin-location-report-specialties-card">
                                        {sortedMedicationsAvailable.map(medication => (
                                            <div key={medication.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="medication"
                                                            color="primary"
                                                            checked={medicationIds[medication.id] || false}
                                                            onChange={event => {
                                                                setMedicationIds({
                                                                    ...medicationIds,
                                                                    [medication.id]: event.target.checked
                                                                });
                                                            }}  />
                                                    }
                                                    label={`${medication.name} (${medication.numSearches})`}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className="flex-1 relative admin-query-report-map-container">
                        <GoogleHeatMap data={filteredResults} onMap={map => setMap(map)} />
                    </div>
                </div>
            }
        </div>
    )
};

function _formatDate(date) {
    return format(date, 'yyyy-MM-dd');
}

function _getSpecialtyById(specialtyId, specialties) {
    return specialties.find(specialty => specialty.id === specialtyId);
}

function _getMedicationById(medicationId, medicationsAvailable) {
    return medicationsAvailable.find(medication => medication.id === medicationId);
}

// Returns true if there are any 'true' values in the Object
function _hasTrueValue(map) {
    return Object.values(map).reduce((ret, value) => ret || value, false);
}

export default AdminQueryReport;
