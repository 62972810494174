import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from "@mui/material/Button";
import UserIcon from '@mui/icons-material/AccountCircle';

import Nav from '../nav';
import AdminLocationReport from '../adminLocationReport';
import AdminQueryReport from '../adminQueryReport';
import AdminMembershipReport from '../adminMembershipReport';
import PurchasablesReport from "../purchasablesReport";
import SalesTaxReport from "../salesTaxReport";
import NICADialog from "../_common/dialog";
import useFetchReportsRestrictions from "../../hooks/useFetchReportsRestrictions";

import './index.css';
import AdminUserImpersonate from "../adminUserImpersonate";
import AdminOnly from "../_common/adminOnly";
import WhitelabelReport from "../whitelabelReport";

const Home = () => {
    const [showAdminUserImpersonate, setShowAdminUserImpersonate] = useState(false);
    useFetchReportsRestrictions();
    // TODO: These need to change before production!!
    // const locationFinderUrl = isDev() ? "https://nica-locator-dev.web.app" : "https://locator.infusioncenter.org";
    // const openLocationFinder = () => window.open(locationFinderUrl);

    return (
        <Router>
            <div>
                <div className="home-nav-container">
                    <Nav />
                </div>
                <div className="home-content-container relative">
                    <AppBar position="sticky" color="primary">
                        <Toolbar disableGutters={true}>
                            <div className="home-toolbar-title-container text-left flex w-full">
                                <div className="home-toolbar-title flex items-center flex-1">
                                    <Switch>
                                        <Route path="/location-report" render={() => "Location Report"} />
                                        <Route path="/query-report" render={() => "Query Report"} />
                                        <Route path="/membership-report" render={() => "Membership Report"} />
                                        <Route path="/sales-tax-report" render={() => "Sales Tax Report"} />
                                        <Router path="/whitelabel-report" render={() => "Whitelabel Report"} />
                                        <Route render={() => "Location Report"} />
                                    </Switch>
                                </div>
                                <AdminOnly noRedirect={true}>
                                    <div className="mr-3">
                                        <Button color="secondary" variant="text" onClick={() => setShowAdminUserImpersonate(true)}>
                                            <UserIcon className="mr-1" /><span className="text-white">Impersonate User</span>
                                        </Button>
                                    </div>
                                </AdminOnly>
                                {/*<div className="mr-[150px]">*/}
                                {/*    <Button color="secondary" variant="text" onClick={openLocationFinder}>*/}
                                {/*        <span className="text-white">Location Finder</span>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className="flex-1">
                        <Switch>
                            <Route path="/location-report" component={AdminLocationReport} />
                            <Route path="/query-report" component={AdminQueryReport} />
                            <Route path="/membership-report" component={AdminMembershipReport} />
                            <Route path="/sales-tax-report" component={SalesTaxReport} />
                            <Route path="/purchasables-report" component={PurchasablesReport} />
                            <Route path="/whitelabel-report" component={WhitelabelReport} />
                            <Route path="/" render={() => <Redirect to="/location-report" />} />
                        </Switch>
                    </div>
                </div>
            </div>
            <NICADialog
                open={showAdminUserImpersonate}
                onClose={() => setShowAdminUserImpersonate(false)}
                title="Impersonate User"
                actions={[{ label: 'Done', onClick: () => setShowAdminUserImpersonate(false)}]}>
                <AdminUserImpersonate onClose={() => setShowAdminUserImpersonate(false)} />
            </NICADialog>
        </Router>
    )
};

export default Home;
