const ids = {
    facilityType: "04ec22a4",
    acceptedHealthCarePlans: "3cafb20d",
    acceptsOutsidePatients: "24331fda",
    acceptsOutsidePatientsYes: "6c25249b",
    hospitalBased: "024a0dd9",
    acceptsMedicare: "bc5b0e8a",
    acceptsMedicaid: "d1802d01",
    medicationsAvailable: "374f7529",
    practiceSpecialty: "2b7ddb01",
    amenities: "f2755b5b",
    languagesSpoken: "c39240db",
    practiceType: "4ce48f84",
    hospitalBasedAttribute: "04ec22a4"
};

export default ids;
