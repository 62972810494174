import {useShallowEqualSelector} from "../index";
import {parsePoint} from "../../utils/point";

/**
 * Returns the data necessary to render the Admin Query Report
 */
export const useQueriesSelector = () => {
    return useShallowEqualSelector(({ queries }) => {
        const { loading, results, error, specialties, medicationsAvailable, specialtyQueries, medicationQueries } = queries;
        return {
            loading,
            results: results ? results.map(result => ({
                ...result,
                ...parsePoint(result.p)
            })) : null,
            resultsCount: results ? results.length : 0,
            specialtyQueries: specialties,
            medicationQueries: medicationsAvailable,
            specialtyQueryIds: specialtyQueries,
            medicationQueryIds: medicationQueries,
            error
        };
    });
};