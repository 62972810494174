import {
    PURCHASABLES_LOADING,
    PURCHASABLES_SUCCESS,
    PURCHASABLES_ERROR
} from "./types";

/**
 * Fetches all the Member Resources, which are resources that can be "Purchased" by NICA members
 */
export const loadPurchasables = (enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch({ type: PURCHASABLES_LOADING });
        return api.getAllPurchasables()
            .then(purchasables => {
                console.log("purchasables", purchasables);
                return dispatch({ type: PURCHASABLES_SUCCESS, payload: purchasables });
            })
            .catch(error => {
                console.error("loadPurchasables", error);
                dispatch({ type: PURCHASABLES_ERROR, error });
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const getMembershipsForLocations = (locationIds, setLoading, setRecords, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.getMembershipsForLocations(locationIds)
            .then(memberships => {
                console.log("memberships", memberships);
                setLoading(false);
                setRecords(memberships);
            })
            .catch(error => {
                setLoading(false);
                console.error("getMembershipsForLocations", error);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const getMembershipsById = (activePurchasableIds, setLoading, setRecords, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        console.log("activePurchasableIds", activePurchasableIds);
        return api.getMembershipsById(activePurchasableIds)
            .then(memberships => {
                console.log("memberships", memberships);
                setLoading(false);
                setRecords(memberships);
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
)