import { useEffect } from 'react';
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getReportRestrictions } from "../redux/login";
import { reportsRestrictionsSelector, useLoginSelector } from "../redux/login/selectors";

function useFetchReportsRestrictions() {
    const reportsRestrictions = useSelector(reportsRestrictionsSelector);
    const { claims } = useLoginSelector();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    // Fetches any user-specific reports restrictions
    useEffect(() => {
        if (!reportsRestrictions) {
            dispatch(getReportRestrictions(claims.email, enqueueSnackbar));
        }
    }, [reportsRestrictions, claims.email, dispatch, enqueueSnackbar]);

    return reportsRestrictions;
}

export default useFetchReportsRestrictions;
