import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch} from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import cx from "classnames";

import {useSnackbar} from "notistack";
import {subMonths, addMonths, format} from "date-fns";

import MembershipList from "./memberships";
import Loading from "../_common/loading";
import NICADialog from "../_common/dialog";
import AdminOnly from "../_common/adminOnly";
import {loadPurchasables} from "../../redux/purchasables/actions";
import {
    loadPendingCheckPurchases,
    loadPurchasablesReport,
    markCheckPaymentReceived,
    voidPayment
} from "../../redux/purchasablesReport";
import {usePurchasablesReportSelector} from "../../redux/purchasablesReport/selectors";
import {usePurchasablesSelector} from "../../redux/purchasables/selectors";
import {formatMoneyFromPennies} from "../../utils/money";

import './index.css';

const PROJECTED_REVENUE_HEADERS_MONTHS = 12;
const TOTAL_REVENUE_HEADERS_MONTHS = 12;
const EXPIRED_HEADERS_MONTHS = 12;
const EXPIRING_HEADERS_MONTHS = 13;
const KEY_FORMAT = "MMM yy";

const PurchasablesReport = () => {
    const [showConfirm, setShowConfirm] = useState(null);
    const [showVoidConfirm, setShowVoidConfirm] = useState(null);
    const [filterIds, setFilterIds] = useState(null);
    const [membershipDetails, setMembershipDetails] = useState(null);
    const {reportLoading, report, pendingCheckPayments} = usePurchasablesReportSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {purchasables} = usePurchasablesSelector();

    useEffect(() => {
        dispatch(loadPurchasablesReport(enqueueSnackbar));
        dispatch(loadPurchasables(enqueueSnackbar));
        dispatch(loadPendingCheckPurchases(() => {}, enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    const reportData = useMemo(() => {
        if (report) {
            const projectedRevenueHeaders = _buildHeaders(PROJECTED_REVENUE_HEADERS_MONTHS, new Date());
            const totalRevenueHeaders = _buildHeaders(TOTAL_REVENUE_HEADERS_MONTHS, subMonths(new Date(), TOTAL_REVENUE_HEADERS_MONTHS - 1));
            const expiredHeaders = _buildHeaders(EXPIRED_HEADERS_MONTHS, subMonths(new Date(), EXPIRED_HEADERS_MONTHS - 1));
            const expiringHeaders = _buildHeaders(EXPIRING_HEADERS_MONTHS, new Date());

            const numAutoRenewMemberships = report.autoRenews.length;

            const projectedRevenueByMonth = _getProjectedRevenueByMonth(report, filterIds);
            const totalRevenueByMonth = _getTotalRevenueByMonth(report, filterIds);
            const expiringByMonth = _getNumMembershipsExpiringByMonth(report, filterIds);
            const expiredByMonth = _getNumMembershipsExpiredByMonth(report, filterIds);
            const purchasableIds = _getPurchasableIds(report);
            const purchasablesFilterItems = purchasables ? purchasableIds.map(id => purchasables.find(item => item.id === id)) : []

            return {
                projectedRevenueHeaders,
                totalRevenueHeaders,
                expiredHeaders,
                expiringHeaders,
                numAutoRenewMemberships,
                projectedRevenueByMonth,
                totalRevenueByMonth,
                expiringByMonth,
                expiredByMonth,
                purchasableIds,
                purchasablesFilterItems
            }
        }
    }, [reportLoading, report, purchasables, filterIds]); // eslint-disable-line react-hooks/exhaustive-deps

    // Sets the initial selected ids
    useEffect(() => {
        if (!filterIds && reportData?.purchasableIds) {
            setFilterIds([...reportData.purchasableIds]);
        }
    }, [reportData, filterIds]);

    console.log("reportData", reportData, reportLoading, report);

    const onAutoRenewMembershipsClick = () => {
        if (report && report.autoRenews && report.autoRenews.length) {
            setMembershipDetails({ activePurchasables: report.autoRenews });
        }
    };

    const onMonthlyPurchasablesRollupClick = (rollup) => {
        if (rollup && rollup.total > 0) {
            setMembershipDetails({ activePurchasables: rollup.records });
        }
    };

    const onMarkPaymentReceived = () => {
        dispatch(markCheckPaymentReceived(pendingCheckPayments[showConfirm], () => {}, enqueueSnackbar));
        setShowConfirm(false);
    };

    const onVoidPayment = () => {
        dispatch(voidPayment(pendingCheckPayments[showVoidConfirm].id, () => {}, enqueueSnackbar));
        setShowVoidConfirm(false);
    };

    return (
        <AdminOnly>
            <div className="app-page flex flex-col">
                {reportLoading || !reportData ?
                    <Loading size={45} />
                    :
                    <div className="flex flex-col flex-1 membership-report-container">
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="specialties">
                                    <div className="flex w-full">
                                        <div className="flex items-center admin-query-report-section-label flex-1">
                                            Purchasables
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setFilterIds([...(reportData?.purchasableIds || [])]);
                                                event.stopPropagation();
                                            }}>
                                                Select All
                                            </Button>
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setFilterIds([]);
                                                event.stopPropagation();
                                            }}>
                                                Unselect All
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="purchasables-report-purchasables-card">
                                        {reportData.purchasablesFilterItems.map(purchasable => {
                                            return purchasable ? (
                                                <div key={purchasable.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="purchasable"
                                                                color="primary"
                                                                checked={filterIds?.includes(purchasable.id) || false}
                                                                onChange={event => {
                                                                    if (event.target.checked) {
                                                                        setFilterIds([...filterIds, purchasable.id]);
                                                                    } else {
                                                                        setFilterIds(filterIds.filter(id => id !== purchasable.id));
                                                                    }
                                                                }}  />
                                                        }
                                                        label={`${purchasable.title}`}
                                                    />
                                                </div>
                                            ) : null})}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <div className="membership-report-header mt-10">
                                Overview
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                    # Auto-Renew Purchasbles
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell onClick={onAutoRenewMembershipsClick} style={{cursor: "pointer"}}>
                                                    {reportData.numAutoRenewMemberships}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                Total Revenue by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.totalRevenueHeaders.map((header, index) => (
                                                    <TableCell key={`total-rev-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.totalRevenueHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`total-rev-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.totalRevenueByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.totalRevenueByMonth[header] ?
                                                            formatMoneyFromPennies(reportData.totalRevenueByMonth[header].total)
                                                            :
                                                            "$0"
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                Projected Revenue by Month (from Auto-Renews)
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.projectedRevenueHeaders.map((header, index) => (
                                                    <TableCell key={`projected-rev-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.projectedRevenueHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`projected-rev-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.projectedRevenueByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.projectedRevenueByMonth[header] ?
                                                            formatMoneyFromPennies(reportData.projectedRevenueByMonth[header].total)
                                                            :
                                                            "$0"
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                # Expiring Purchasables by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.expiringHeaders.map((header, index) => (
                                                    <TableCell key={`expiring-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.expiringHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`expiring-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.expiringByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.expiringByMonth[header] ?
                                                            reportData.expiringByMonth[header].total
                                                            :
                                                            0
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                # Expired Purchasables by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.expiredHeaders.map((header, index) => (
                                                    <TableCell key={`expired-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.expiredHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`expired-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.expiredByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.expiredByMonth[header] ?
                                                            reportData.expiredByMonth[header].total
                                                            :
                                                            0
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                Pending Check Payments
                            </div>
                            <Card>
                                <CardContent>
                                    {pendingCheckPayments?.length > 0 ?
                                        pendingCheckPayments?.map((purchase, index) => (
                                            <div key={`purchase-${purchase.id}`} className={cx("flex", {
                                                "mb-5": index < pendingCheckPayments.length - 1
                                            })}>
                                                <div className="flex-1">
                                                    <div style={{fontSize: 14}}>
                                                        {format(purchase.createdAt, "MM/dd/yyyy")}
                                                    </div>
                                                    <div>
                                                        {purchase.contactInfo.firstName} {purchase.contactInfo.lastName}
                                                    </div>
                                                    <div style={{fontWeight: "bold"}}>
                                                        {formatMoneyFromPennies(purchase.total)}
                                                    </div>
                                                </div>
                                                <div className="mr-5 flex items-center">
                                                    <Button variant="contained" color="primary" onClick={() => setShowConfirm(index)}>
                                                        Payment Received
                                                    </Button>
                                                </div>
                                                <div className="flex items-center">
                                                    <Button variant="contained" color="primary" onClick={() => setShowVoidConfirm(index)}>
                                                        Void
                                                    </Button>
                                                </div>
                                            </div>
                                        )) :
                                        <div>
                                            No pending check payments.
                                        </div>
                                    }
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                }
                <NICADialog
                    open={Boolean(membershipDetails)}
                    onClose={() => setMembershipDetails(null)}
                    title="Membership Details"
                    maxWidth="md"
                    actions={[{label: 'done', onClick: () => setMembershipDetails(null)}]}>
                    <MembershipList {...membershipDetails} />
                </NICADialog>
                <NICADialog
                    open={showConfirm === 0 || Boolean(showConfirm)}
                    onClose={() => setShowConfirm(null)}
                    title="Mark Payment Received"
                    maxWidth="md"
                    actions={[
                        {label: 'Cancel', onClick: () => setShowConfirm(null)},
                        {label: 'Confirm', onClick: onMarkPaymentReceived}
                        ]}>
                        Mark {(showConfirm === 0 || showConfirm) && formatMoneyFromPennies(pendingCheckPayments[showConfirm].total)} payment received?
                </NICADialog>
                <NICADialog
                    open={showVoidConfirm === 0 || Boolean(showVoidConfirm)}
                    onClose={() => setShowVoidConfirm(null)}
                    title="Void Purchase"
                    maxWidth="md"
                    actions={[
                        {label: 'Cancel', onClick: () => setShowVoidConfirm(null)},
                        {label: 'Confirm', onClick: onVoidPayment}
                    ]}>
                    Void {(showVoidConfirm === 0 || showVoidConfirm) && formatMoneyFromPennies(pendingCheckPayments[showVoidConfirm].total)} payment?
                </NICADialog>
            </div>
        </AdminOnly>
    )
};

function _buildHeaders(numMonths, start) {
    const headers = [];
    for(let index = 0; index < numMonths; index++) {
        headers.push(format(start, KEY_FORMAT));
        start = addMonths(start, 1);
    }
    return headers;
}

function _getProjectedRevenueByMonth(report, filterIds) {
    return report.autoRenews.reduce((ret, activePurchasable) => {
        if (filterIds?.includes(activePurchasable.id)) {
            const renewalDate = addMonths(activePurchasable.updatedAt, activePurchasable.creditsRemaining);
            const dateKey = format(renewalDate, KEY_FORMAT);
            if (ret[dateKey]) {
                ret[dateKey].total += activePurchasable.total;
                ret[dateKey].records.push(activePurchasable);
            } else {
                ret[dateKey] = {
                    total: activePurchasable.total,
                    records: [activePurchasable]
                };
            }
        }
        return ret;
    }, {});
}

function _getTotalRevenueByMonth(report, filterIds) {
    return report.created.reduce((ret, purchaseEvent) => {
        if (filterIds?.includes(purchaseEvent.purchasableId)) {
            const purchaseDate = new Date(purchaseEvent.date.value);
            const dateKey = format(purchaseDate, KEY_FORMAT);
            if (ret[dateKey]) {
                ret[dateKey].total += purchaseEvent.total;
                ret[dateKey].records.push(JSON.parse(purchaseEvent.json));
            } else {
                ret[dateKey] = {
                    total: purchaseEvent.total,
                    records: [JSON.parse(purchaseEvent.json)]
                };
            }
        }
        return ret;
    }, {});
}

function _getNumMembershipsExpiringByMonth(report, filterIds) {
    return report.expiring.reduce((ret, activePurchasable) => {
        if (filterIds?.includes(activePurchasable.id)) {
            const expirationDate = addMonths(activePurchasable.updatedAt, activePurchasable.creditsRemaining);
            const dateKey = format(expirationDate, KEY_FORMAT);
            if (ret[dateKey]) {
                ret[dateKey].total += 1;
                ret[dateKey].records.push(activePurchasable);
            } else {
                ret[dateKey] = {
                    total: 1,
                    records: [activePurchasable]
                };
            }
        }
        return ret;
    }, {});
}

function _getNumMembershipsExpiredByMonth(report, filterIds) {
    return report.expired.reduce((ret, purchaseEvent) => {
        if (filterIds?.includes(purchaseEvent.purchasableId)) {
            const expiredDate = new Date(purchaseEvent.date.value);
            const dateKey = format(expiredDate, KEY_FORMAT);
            if (ret[dateKey]) {
                ret[dateKey].total += 1;
                ret[dateKey].records.push(JSON.parse(purchaseEvent.json));
            } else {
                ret[dateKey] = {
                    total: 1,
                    records: [JSON.parse(purchaseEvent.json)]
                };
            }
        }
        return ret;
    }, {});
}

function _getPurchasableIds(report) {
    const expiredIds = report.expired.concat(report.created).reduce((ret, item) => ret.add(item.purchasableId), new Set());
    return [...expiredIds];
}

export default PurchasablesReport;
