/*global google*/
/*eslint no-undef: "error"*/

import React, {useEffect, useState} from 'react';


/**
 * TODO: Let's make sure this API Key is from Prod. I'm OK wit hard-coding it into the project so long as it's not from Dev
 */
const GoogleHeatMap = ({ data, lat, lng, zoom, onMap }) => {
    const [heatMap, setHeatMap] = useState(null);

    useEffect(() => {
        if (!window.initHeatMap) {
            window.initHeatMap = initMap;
            const script = document.createElement("script");
            script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCSvWkVdrGsFpOsrnvCLVFZWpGgtGbrVRw&libraries=visualization,places&callback=initHeatMap";
            script.async = true;
            document.body.appendChild(script);
        } else {
            initMap();
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (heatMap) {
            heatMap.map.setCenter(new google.maps.LatLng(lat, lng));
            heatMap.map.setZoom(zoom);
        }
    }, [lat, lng, zoom]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (heatMap && data) {
            heatMap.setData(data.map(item => new google.maps.LatLng(item.lat, item.lng)));
        }
    }, [heatMap, data]);

    const initMap = () => {
        let config = { center: {lat, lng}, zoom };
        let map = new google.maps.Map(document.getElementById('map'), config);
        let heatmap = new google.maps.visualization.HeatmapLayer({
            data: data.map(item => new google.maps.LatLng(item.lat, item.lng)),
            map: map,
            radius: 20,
            gradient: [
                'rgba(0, 255, 255, 0)',
                // 'rgba(0, 255, 255, 1)',
                // 'rgba(0, 191, 255, 1)',
                'rgba(0, 127, 255, 1)',
                'rgba(0, 63, 255, 1)',
                'rgba(0, 0, 255, 1)',
                'rgba(0, 0, 223, 1)',
                'rgba(0, 0, 191, 1)',
                'rgba(0, 0, 159, 1)',
                'rgba(0, 0, 127, 1)',
                'rgba(63, 0, 91, 1)',
                'rgba(127, 0, 63, 1)',
                'rgba(191, 0, 31, 1)',
                'rgba(255, 0, 0, 1)'
            ]
        });

        setHeatMap(heatmap);
        if (onMap) {
            onMap(heatmap);
        }
    };

    // if (heatMap && data) {
    //     heatMap.setData(data.map(item => new google.maps.LatLng(item.lat, item.lng)));
    // }

    return (
        <div id="map" style={{position: "absolute", top: 0, right: 0, left: 0, bottom: 0}}>
        </div>
    );
};

GoogleHeatMap.defaultProps = {
    lat: 38.826557,
    lng: -96.111918,
    zoom: 4
};

export default GoogleHeatMap;
