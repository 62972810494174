import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {useSnackbar} from "notistack";
import {subMonths, addMonths, format} from "date-fns";

import MembershipList from "./memberships";
import Loading from "../_common/loading";
import NICADialog from "../_common/dialog";
import AdminOnly from "../_common/adminOnly";
import {loadMembershipReport} from "../../redux/membershipReport/actions";
import {useMembershipReportSelector} from "../../redux/membershipReport/selectors";
import {formatMoneyFromPennies} from "../../utils/money";

import './index.css';

const PROJECTED_REVENUE_HEADERS_MONTHS = 12;
const TOTAL_REVENUE_HEADERS_MONTHS = 12;
const EXPIRED_HEADERS_MONTHS = 12;
const EXPIRING_HEADERS_MONTHS = 13;
const KEY_FORMAT = "MMM yy";

const AdminMembershipReport = () => {
    const [membershipDetails, setMembershipDetails] = useState(null);
    const {reportLoading, report} = useMembershipReportSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        dispatch(loadMembershipReport(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    const reportData = useMemo(() => {
        if (report) {
            const projectedRevenueHeaders = _buildHeaders(PROJECTED_REVENUE_HEADERS_MONTHS, new Date());
            const totalRevenueHeaders = _buildHeaders(TOTAL_REVENUE_HEADERS_MONTHS, subMonths(new Date(), TOTAL_REVENUE_HEADERS_MONTHS - 1));
            const expiredHeaders = _buildHeaders(EXPIRED_HEADERS_MONTHS, subMonths(new Date(), EXPIRED_HEADERS_MONTHS - 1));
            const expiringHeaders = _buildHeaders(EXPIRING_HEADERS_MONTHS, new Date());

            const numMemberLocations = report.memberLocations.length;
            const numAutoRenewMemberships = report.autoRenewMemberships.length;

            const projectedRevenueByMonth = _getProjectedRevenueByMonth(report);
            const totalRevenueByMonth = _getTotalRevenueByMonth(report);
            const expiringByMonth = _getNumMembershipsExpiringByMonth(report);
            const expiredByMonth = _getNumMembershipsExpiredByMonth(report);

            console.log("projectedRevenueByMonth", projectedRevenueByMonth);
            console.log("totalRevenueByMonth", totalRevenueByMonth);
            console.log("expiringByMonth", expiringByMonth);
            console.log("expiredByMonth", expiredByMonth);

            return {
                projectedRevenueHeaders,
                totalRevenueHeaders,
                expiredHeaders,
                expiringHeaders,
                numMemberLocations,
                numAutoRenewMemberships,
                projectedRevenueByMonth,
                totalRevenueByMonth,
                expiringByMonth,
                expiredByMonth
            }
        }
    }, [reportLoading, report]); // eslint-disable-line react-hooks/exhaustive-deps

    console.log("reportData", reportData, reportLoading, report);

    const onMemberLocationsClick = () => {
        if (report && report.memberLocations && report.memberLocations.length) {
            setMembershipDetails({ locationIds: report.memberLocations.map(location => location.id) });
        }
    };

    const onAutoRenewMembershipsClick = () => {
        if (report && report.autoRenewMemberships && report.autoRenewMemberships.length) {
            setMembershipDetails({ activePurchasables: report.autoRenewMemberships });
        }
    };

    const onMonthlyPurchasablesRollupClick = (rollup) => {
        if (rollup && rollup.total > 0) {
            setMembershipDetails({ activePurchasables: rollup.records });
        }
    };

    return (
        <AdminOnly>
            <div className="app-page flex flex-col">
                {reportLoading || !reportData ?
                    <Loading size={45} />
                    :
                    <div className="flex flex-col flex-1 membership-report-container">
                        <div>
                            <div className="membership-report-header">
                                Overview
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                    # Member Locations
                                                </TableCell>
                                                <TableCell style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                    # Auto-Renew Memberships
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell onClick={onMemberLocationsClick} style={{cursor: "pointer"}}>
                                                    {reportData.numMemberLocations}
                                                </TableCell>
                                                <TableCell onClick={onAutoRenewMembershipsClick} style={{cursor: "pointer"}}>
                                                    {reportData.numAutoRenewMemberships}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                Total Revenue by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.totalRevenueHeaders.map((header, index) => (
                                                    <TableCell key={`total-rev-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.totalRevenueHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`total-rev-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.totalRevenueByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.totalRevenueByMonth[header] ?
                                                            formatMoneyFromPennies(reportData.totalRevenueByMonth[header].total)
                                                            :
                                                            "$0"
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                Projected Revenue by Month (from Auto-Renews)
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.projectedRevenueHeaders.map((header, index) => (
                                                    <TableCell key={`projected-rev-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.projectedRevenueHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`projected-rev-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.projectedRevenueByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.projectedRevenueByMonth[header] ?
                                                            formatMoneyFromPennies(reportData.projectedRevenueByMonth[header].total)
                                                            :
                                                            "$0"
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                # Expiring Memberships by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.expiringHeaders.map((header, index) => (
                                                    <TableCell key={`expiring-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.expiringHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`expiring-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.expiringByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.expiringByMonth[header] ?
                                                            reportData.expiringByMonth[header].total
                                                            :
                                                            0
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                            <div className="membership-report-header mt-10">
                                # Expired Memberships by Month
                            </div>
                            <Paper>
                                <div className="overflow-x-scroll">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {reportData.expiredHeaders.map((header, index) => (
                                                    <TableCell key={`expired-header-${header}`} style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                                                        {header}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {reportData.expiredHeaders.map((header, fieldIndex) => (
                                                    <TableCell key={`expired-${header}`} onClick={() => onMonthlyPurchasablesRollupClick(reportData.expiredByMonth[header])} style={{cursor: "pointer"}}>
                                                        {reportData.expiredByMonth[header] ?
                                                            reportData.expiredByMonth[header].total
                                                            :
                                                            0
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </div>
                    </div>
                }
                <NICADialog
                    open={Boolean(membershipDetails)}
                    onClose={() => setMembershipDetails(null)}
                    title="Membership Details"
                    maxWidth="md"
                    actions={[{label: 'done', onClick: () => setMembershipDetails(null)}]}>
                    <MembershipList {...membershipDetails} />
                </NICADialog>
            </div>
        </AdminOnly>
    )
};

function _buildHeaders(numMonths, start) {
    const headers = [];
    for(let index = 0; index < numMonths; index++) {
        headers.push(format(start, KEY_FORMAT));
        start = addMonths(start, 1);
    }
    return headers;
}

function _getProjectedRevenueByMonth(report) {
    return report.autoRenewMemberships.reduce((ret, activePurchasable) => {
        const renewalDate = addMonths(activePurchasable.updatedAt, activePurchasable.creditsRemaining);
        const dateKey = format(renewalDate, KEY_FORMAT);
        if (ret[dateKey]) {
            ret[dateKey].total += activePurchasable.total;
            ret[dateKey].records.push(activePurchasable);
        } else {
            ret[dateKey] = {
                total: activePurchasable.total,
                records: [activePurchasable]
            };
        }
        return ret;
    }, {});
}

function _getTotalRevenueByMonth(report) {
    return report.created.reduce((ret, purchaseEvent) => {
        const purchaseDate = new Date(purchaseEvent.date.value);
        const dateKey = format(purchaseDate, KEY_FORMAT);
        if (ret[dateKey]) {
            ret[dateKey].total += purchaseEvent.total;
            ret[dateKey].records.push(JSON.parse(purchaseEvent.json));
        } else {
            ret[dateKey] = {
                total: purchaseEvent.total,
                records: [JSON.parse(purchaseEvent.json)]
            };
        }
        return ret;
    }, {});
}

function _getNumMembershipsExpiringByMonth(report) {
    return report.expiringMemberships.reduce((ret, activePurchasable) => {
        const expirationDate = addMonths(activePurchasable.updatedAt, activePurchasable.creditsRemaining);
        const dateKey = format(expirationDate, KEY_FORMAT);
        if (ret[dateKey]) {
            ret[dateKey].total += 1;
            ret[dateKey].records.push(activePurchasable);
        } else {
            ret[dateKey] = {
                total: 1,
                records: [activePurchasable]
            };
        }
        return ret;
    }, {}); 
}

function _getNumMembershipsExpiredByMonth(report) {
    return report.expired.reduce((ret, purchaseEvent) => {
        const expiredDate = new Date(purchaseEvent.date.value);
        const dateKey = format(expiredDate, KEY_FORMAT);
        if (ret[dateKey]) {
            ret[dateKey].total += 1;
            ret[dateKey].records.push(JSON.parse(purchaseEvent.json));
        } else {
            ret[dateKey] = {
                total: 1,
                records: [JSON.parse(purchaseEvent.json)]
            };
        }
        return ret;
    }, {});
}

export default AdminMembershipReport;
