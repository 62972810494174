/*global google*/
/*eslint no-undef: "error"*/

import React, { useRef, useEffect } from 'react';
import TextField from "@mui/material/TextField";

import RadiusSelect from "../radiusSelect";

import './index.css';

const DEFAULT_LAT = 30.3079823;
const DEFAULT_LNG = -97.8938278;

const PlacesSearch = ({ map, radius, onSearchChange, onRadiusChange }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (map && ref.current) {
            const options = {
                // bounds: defaultBounds,
                componentRestrictions: { country: "us" },
                fields: ["geometry.location"],
                origin: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
                strictBounds: false,
                types: ["(regions)"],
            };

            const autocomplete = new google.maps.places.Autocomplete(ref.current, options);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                onSearchChange({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
            });
        }
    }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="flex" style={{alignItems: "flex-end"}}>
            <div className="flex-1">
                <TextField
                    placeholder="Austin, TX"
                    label="Search"
                    variant="standard"
                    inputRef={ref}
                    fullWidth />
            </div>
            <div className="ml-5 flex-1">
                <RadiusSelect radius={radius} onChange={e => onRadiusChange(e.target.value)} />
            </div>
        </div>
    )
};

export default PlacesSearch;
