import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const RadiusSelect = ({ radius, ...otherProps }) => {
    return (
        <Select
            value={radius}
            variant="standard"
            {...otherProps}>
            {_getDistanceOptions().map((option) => {
                return (
                    <MenuItem value={option} key={option}>
                        {option + " miles"}
                    </MenuItem>
                )
            })}
        </Select>
    )
};

function _getDistanceOptions() {
    const ret = [];
    for (let i = 1; i * 5 <= 100; i++) {
        ret.push(i * 5);
    }
    return ret;
}

export default RadiusSelect;
