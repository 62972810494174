import {
    PURCHASABLES_LOADING,
    PURCHASABLES_SUCCESS,
    PURCHASABLES_ERROR
} from "./types";

const INITIAL_STATE = {
    purchasablesLoading: true,
    purchasablesMap: {},
    purchasablesIds: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PURCHASABLES_LOADING:
            return {
                ...state,
                purchasablesLoading: true
            };
        case PURCHASABLES_SUCCESS:
            return {
                ...state,
                purchasablesLoading: false,
                purchasablesMap: action.payload.reduce((ret, purchasable) => {
                    ret[purchasable.id] = purchasable;
                    return ret;
                }, {}),
                purchasablesIds: action.payload.map(purchasable => purchasable.id)
            };
        case PURCHASABLES_ERROR:
            return {
                ...state,
                purchasablesLoading: false
            };
        default:
            return state;
    }
};

export default reducer;