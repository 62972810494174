import React from 'react';
import {Formik, Form} from "formik";
import {object, string} from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import {signInWithEmailPassword} from "../../../redux/login";
import {useLoginSelector} from "../../../redux/login/selectors";

import './index.scss';

const LoginForm = ({ setShowForgotPassword }) => {
    const {loggingIn} = useLoginSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <div>
            <div className="lato login-title">
                Sign In
            </div>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={object().shape({
                    email: string().email("Please enter a valid email").required(),
                    password: string().required("Please enter a password")
                })}
                onSubmit={({email, password}) => {
                    dispatch(signInWithEmailPassword(email, password, enqueueSnackbar));
                }}>
                <Form>
                    <div className="login-form-row">
                        <CustomFormikField
                            name="email"
                            label="Email"
                            placeholder="john.smith@gmail.com"
                            CustomComponent={TextField}
                            fullWidth />
                    </div>
                    <div className="login-form-row">
                        <CustomFormikField
                            name="password"
                            label="Password"
                            type="password"
                            CustomComponent={TextField}
                            fullWidth />
                    </div>
                    {loggingIn ?
                        <div className="flex justify-center items-center">
                            <CircularProgress size={35} />
                        </div> :
                        <>
                            <div className="mt-8 login-form-row flex justify-center items-center">
                                <Button variant="contained" color="primary" type="Submit" fullWidth>
                                    Login
                                </Button>
                            </div>
                            <div className="flex justify-center items-center">
                                <Button variant="text" onClick={() => setShowForgotPassword(true)}>
                                    Forgot Password?
                                </Button>
                            </div>
                        </>
                    }
                </Form>
            </Formik>
        </div>
    );
};

export default LoginForm;
