import { useState } from "react";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from "@mui/material/CircularProgress";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, TableHead } from '@mui/material';
import { addDays, subWeeks, format } from 'date-fns';
import {useSnackbar} from "notistack";
import CardContent from "@mui/material/CardContent";
import { formatMoneyFromPennies } from "../../utils/money"
import { loadSalesTaxReport } from "../../redux/salesTaxReport";
import { useDispatch } from "react-redux";
import { useSalesTaxReportSelector } from "../../redux/salesTaxReport/selectors";

export default function SalesTaxReport() {
    const [startDate, setStartDate] = useState(subWeeks(new Date(), 1));
    const [endDate, setEndDate] = useState(addDays(new Date(), 1));
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const { report, reportLoading } = useSalesTaxReportSelector();

    const handleReportClick = () => {
        const start = format(startDate, "yyyy-MM-dd");
        const end = format(endDate, "yyyy-MM-dd");
        dispatch(loadSalesTaxReport(start, end, enqueueSnackbar));
    }

  console.log("Report: ", report);

    return (
      <div className="p-8">
          <Card className="mb-5">
              <CardContent>
                  <div className="flex">
                      <div>
                          <DatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="dense"
                              label="Start Date"
                              value={startDate}
                              onChange={date => setStartDate(date)}
                              autoOk={true}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                          />
                      </div>
                      <div className="ml-8">
                          <DatePicker
                              disableToolbar
                              variant="inline"
                              format="MM/dd/yyyy"
                              margin="dense"
                              label="End Date"
                              value={endDate}
                              onChange={date => setEndDate(date)}
                              autoOk={true}
                              KeyboardButtonProps={{
                                  'aria-label': 'change date',
                              }}
                          />
                      </div>
                      <div className="flex-1" />

                      <div className="ml-8 flex-1 flex items-center justify-end">
                          {reportLoading ?
                              <CircularProgress size={35} />
                              :
                              <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth={false}
                                  onClick={handleReportClick}>
                                  Get Report
                              </Button>
                          }

                      </div>
                  </div>
              </CardContent>
          </Card>
          <TableContainer className="mt-8" component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="salestaxreport table">
                  <TableHead>
                      <TableRow>
                          <TableCell>State</TableCell>
                          <TableCell>Taxable Total</TableCell>
                          <TableCell>Sales Tax Total</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {report && report.data.map(row => (
                          <TableRow key={row.stateCode}>
                              <TableCell>{row.stateCode}</TableCell>
                              <TableCell>{formatMoneyFromPennies(row.taxableTotal)}</TableCell>
                              <TableCell>{formatMoneyFromPennies(row.salesTaxInPennies)}</TableCell>
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>

      </div>
    )
}
