import { createSlice } from '@reduxjs/toolkit'
import { compareAsc } from 'date-fns';

const INITIAL_STATE = {
    reportLoading: false,
    report: null,
    pendingCheckPayments: null
};

const purchasablesReportSlice = createSlice({
    name: 'purchasablesReport',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.reportLoading = action.payload;
        },
        success(state, action) {
            state.reportLoading = false;
            state.report = action.payload;
        },
        pendingCheckPaymentsSuccess(state, action) {
            state.pendingCheckPayments = action.payload;
        }
    }
});

export const { loading, success, pendingCheckPaymentsSuccess } = purchasablesReportSlice.actions;

export default purchasablesReportSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadPurchasablesReport = (enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch(loading(true));
        return api.getPurchasablesReport()
            .then(report => {
                console.log("PurchasablesReport", report);
                return dispatch(success(report));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);

export const loadPendingCheckPurchases = (setLoading, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.purchases.getPendingCheckPaymentPurchases()
            .then(purchases => {
                console.log("purchases", purchases);
                return dispatch(pendingCheckPaymentsSuccess(purchases.sort((p1, p2) => compareAsc(p1.createdAt, p2.createdAt))));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
)

export const markCheckPaymentReceived = (purchase, setLoading, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.purchases.save({
                ...purchase,
                paymentReceived: true
            })
            .then(report => {
                setLoading(false);
                return dispatch(loadPendingCheckPurchases(setLoading));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                setLoading(false);
            })
    }
);

export const voidPayment = (purchaseId, setLoading, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.purchases.delete(purchaseId)
            .then(() => {
                setLoading(false);
                return dispatch(loadPendingCheckPurchases(setLoading));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                setLoading(false);
            })
    }
);