import React, {useState} from 'react';
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import LoginForm from "./loginForm";
import ForgotPassword from "./forgotPassword";

import logo from '../../img/nica-logo.svg';

import './index.css';

const Login = () => {
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    return (
        <div className="login-container flex flex-col">
            <AppBar position="sticky" color="primary">
                <Toolbar disableGutters={true}>
                    <div className="home-toolbar-title-container text-left flex w-full">
                        <img src={logo} alt="nica logo" className="login-nica-logo" />
                    </div>
                </Toolbar>
            </AppBar>
            <div className="login-content flex flex-col justify-center items-center flex-1">
                <div className="login-background" />
                <Card className="login-card">
                    <CardContent>
                        {!showForgotPassword ?
                            <LoginForm setShowForgotPassword={setShowForgotPassword} /> :
                            <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
                        }
                    </CardContent>
                </Card>
            </div>
        </div>
    )
};

export default Login;
