import {
    ORGS_LOADING,
    ORGS_SUCCESS,
    ORG_SUCCESS
} from "./types";

export const loadOrganizations = (orgIds, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch({ type: ORGS_LOADING });
        return api.getOrganizations(orgIds)
            .then(orgs => {
                return dispatch({ type: ORGS_SUCCESS, payload: orgs });
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const saveOrganization = (organization, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.saveOrganization(organization)
            .then(org => {
                return dispatch({ type: ORG_SUCCESS, payload: org });
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const loadOrganizationContacts = (orgId, setLoading, setContacts, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.getOrganization(orgId)
            .then(org => {
                setLoading(false);
                setContacts(org.emails);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);