import { createSlice } from '@reduxjs/toolkit'
import {getLocal, saveLocal} from "../../utils/localStorage";

const KEY_LOGGED_IN = "loggedIn";
const KEY_EMAIL = "authEmail";
const KEY_CLAIMS = "claims";

const INITIAL_STATE = {
    authEmail: getLocal(KEY_EMAIL),
    loggedIn: getLocal(KEY_LOGGED_IN, false) === "true" || false, // read from disk
    loggingIn: false,
    claims: getLocal(KEY_CLAIMS, true) || {},
    reportsRestrictions: null
};

const loginSlice = createSlice({
    name: 'login',
    initialState: INITIAL_STATE,
    reducers: {
        userAuthed(state, action) {
            const { claims } = action.payload;
            saveLocal(KEY_CLAIMS, claims, true);
            saveLocal(KEY_LOGGED_IN, true, false);
            state.claims = claims;
            state.loggingIn = false;
            state.loggedIn = true;
        },
        userUnauthed(state, action) {
            saveLocal(KEY_CLAIMS, {}, true);
            saveLocal(KEY_LOGGED_IN, false, false);
            state.loggedIn = false;
            state.claims = {};
        },
        loggingIn(state, action) {
            state.loggingIn = action.payload;
        },
        reportsRestrictionsSuccess(state, action) {
            state.reportsRestrictions = action.payload;
        }
    }
});

export const { userAuthed, userUnauthed, loggingIn, reportsRestrictionsSuccess } = loginSlice.actions;

export default loginSlice.reducer

// CUSTOM THUNK ACTIONS

export const signInWithEmailPassword = (email, password, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch(loggingIn(true));
        return api.loginWithEmailPassword(email, password)
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const initiatePasswordReset = (email, showConfirmation, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch(loggingIn(true));
        return api.initiatePasswordReset(email)
            .then(() => {
                dispatch(loggingIn(false));
                showConfirmation();
            })
            .catch(error => {
                dispatch(loggingIn(false));
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const signOut = (enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.logout()
            .catch(error => {
                console.error(error);
                enqueueSnackbar(error.message, {variant: 'error'});
            });
    }
);

export const impersonateUser = (email, setLoading, onClose, enqueueSnackbar) => (
    async (dispatch, getState, api) => {
        const response = await api.getUserToken(email);
        dispatch(loggingIn(false));
        if (response.data) {
            onClose();
            return api.loginWithToken(response.data);
        } else {
            enqueueSnackbar("no user found", {variant: 'error'});
        }
    }
);

export const getReportRestrictions = (email, enqueueSnackbar) => (
    async (dispatch, getState, api) => {
        return api.reportsRestrictions.get(email)
            .then(response => {
                console.log("getReportsRestrictions", response);
                dispatch(reportsRestrictionsSuccess(response));
            })
            .catch(err => {
                console.log(err);
                enqueueSnackbar(err.message, { variant: "error" });
            });
    }
);
