import {
    QUERIES_LOADING,
    QUERIES_SUCCESS,
    QUERIES_ERROR
} from "./types";

/**
 * Loads queries from BigQuery
 */
export const fetchAdminReport = (startDate, endDate) => (
    (dispatch, getState, api) => {
        dispatch({ type: QUERIES_LOADING });
        return api.fetchAdminReport(startDate, endDate)
            .then(result => {
                return dispatch({ type: QUERIES_SUCCESS, payload: result });
            })
            .catch(error => {
                console.error(error);
                dispatch({ type: QUERIES_ERROR, error });
            })
    }
);