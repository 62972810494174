import {
    ORGS_LOADING,
    ORGS_SUCCESS,
    ORG_SUCCESS
} from "./types";

const INITIAL_STATE = {
    orgsLoading: false,
    orgMap: {},
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORGS_LOADING:
            return {
                ...state,
                orgsLoading: true
            };
        case ORGS_SUCCESS:
            return {
                ...state,
                orgsLoading: false,
                orgMap: action.payload.reduce((ret, org) => {
                    ret[org.id] = org;
                    return ret;
                }, {})
            };
        case ORG_SUCCESS:
            return {
                ...state,
                orgMap: {
                    ...state.orgMap,
                    [action.payload.id]: action.payload
                }
            };
        default:
            return state;
    }
};

export default reducer;