import {useShallowEqualSelector} from "../index";

export const usePurchasablesSelector = () => {
    return useShallowEqualSelector(({ purchasables: { purchasablesLoading, purchasablesMap, purchasablesIds } }) => {
        return {
            purchasablesLoading,
            purchasablesIds,
            purchasables: purchasablesIds ? purchasablesIds.map(id => purchasablesMap[id]) : null
        }
    });
};

export const usePurchasableSelector = (purchasableId) => {
    return useShallowEqualSelector(({ purchasables: { purchasablesMap } }) => {
        return purchasablesMap[purchasableId];
    });
};