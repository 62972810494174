import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExportIcon from '@mui/icons-material/CloudUpload';
import {useSnackbar} from "notistack";

import GoogleHeatMap from "../_common/googleHeatMap";
import Loading from "../_common/loading";
import PlacesSearch from "../_common/placesSearch";
import {exportLocationReportRecords, loadAdminLocationReport} from "../../redux/locations";
import {loadAttributes} from "../../redux/attributes/actions";
import {
    useAttributeListSelector,
    useMedicationsAvailableSelector,
    useSpecialtiesSelector
} from "../../redux/attributes";
import { distanceInMiles } from "../../utils/meters";
import useFetchReportsRestrictions from "../../hooks/useFetchReportsRestrictions";

import './index.css';
import {reportsRestrictionsSelector} from "../../redux/login/selectors";

const AdminLocationReport = () => {
    const [exporting, setExporting] = useState(false);
    const [map, setMap] = useState(null);
    const [search, setSearch] = useState(null);
    const [radius, setRadius] = useState(100);
    const [specialtyIds, setSpecialtyIds] = useState({});
    const [medicationIds, setMedicationIds] = useState({});
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const {loading: attributesLoading} = useAttributeListSelector();
    const specialties = useSpecialtiesSelector();
    const medicationsAvailable = useMedicationsAvailableSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const reportsRestrictions = useSelector(reportsRestrictionsSelector);
    useFetchReportsRestrictions();

    useEffect(() => {
        dispatch(loadAttributes());
        dispatch(loadAdminLocationReport(setLoading, setReport, enqueueSnackbar));
    }, [dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (report) {
            console.log("report", report);
            setSpecialtyIds(Object.keys(report.specialties).reduce((ret, specialtyId) => {
                ret[specialtyId] = true;
                return ret;
            }, {}));
            if (reportsRestrictions) {
                setMedicationIds({[reportsRestrictions.medicationIds]: true})
            } else {
                setMedicationIds(Object.keys(report.medicationsAvailable).reduce((ret, medicationId) => {
                    ret[medicationId] = true;
                    return ret;
                }, {}));
            }
        }
    }, [report, reportsRestrictions]);

    const {sortedSpecialties, sortedMedicationsAvailable} = useMemo(() => {
        if (report && specialties && medicationsAvailable) {
            const sortedSpecialties = Object.keys(report.specialties)
                .map(specialtyId => {
                    const specialty = _getSpecialtyById(specialtyId, specialties);
                    return {
                        id: specialtyId,
                        numSearches: report.specialties[specialtyId].length,
                        ...specialty,
                    };
                })
                .filter(specialty => Boolean(specialty.name))
                .sort((specialty1, specialty2) => specialty1.name.localeCompare(specialty2.name));

            const sortedMedicationsAvailable = Object.keys(report.medicationsAvailable)
                .map(medicationId => {
                    const medication = _getMedicationById(medicationId, medicationsAvailable);
                    return {
                        id: medicationId,
                        numSearches: report.medicationsAvailable[medicationId].length,
                        ...medication,
                    };
                })
                .filter(medication => reportsRestrictions && reportsRestrictions.medicationIds ? reportsRestrictions.medicationIds.includes(medication.id) : true)
                .filter(medication => Boolean(medication.name))
                .sort((medication1, medication2) => medication1.name.localeCompare(medication2.name));

            return { sortedSpecialties, sortedMedicationsAvailable };
        }
        return {};
    }, [report, specialties, medicationsAvailable, reportsRestrictions]);

    const filteredLocations = useMemo(() => {
        if (report && specialtyIds && medicationIds) {
            const idMap = {};
            Object.keys(specialtyIds).forEach(specialtyId => {
                report.specialties[specialtyId].forEach(locationId => {
                    idMap[locationId] = idMap[locationId] || specialtyIds[specialtyId];
                });
            });
            Object.keys(medicationIds).forEach(medicationId => {
                report.medicationsAvailable[medicationId].forEach(locationId => {
                    idMap[locationId] = idMap[locationId] || medicationIds[medicationId];
                });
            });
            return report.locations.filter(location => {
                if (idMap[location.id]) {
                    if (search) {
                        return distanceInMiles(search.lat, search.lng, location.lat, location.lng) <= radius;
                    }
                    return true;
                }
                return false;
            });
        }
        return null;
    }, [report, specialtyIds, medicationIds, search, radius]);

    const exportRecords = () => {
        setExporting(true);
        const locationIds = filteredLocations.map(location => location.id);
        dispatch(exportLocationReportRecords(locationIds, url => {
            setExporting(false);
            if (url) {
                window.open(url);
            }
        }, enqueueSnackbar));
    };

    return (
        <div className="app-page flex flex-col">
            {attributesLoading || loading || !filteredLocations ?
                <Loading size={45} />
                :
                <div className="flex flex-col flex-1">
                    <div className="admin-query-report-controls">
                        <Card className="mb-5">
                            <CardContent>
                                <div className="flex w-full">
                                    <div className="flex-1">
                                        <PlacesSearch map={map} radius={radius} onSearchChange={search => setSearch(search)} onRadiusChange={radius => setRadius(radius)} />
                                    </div>
                                    <div className="flex justify-end items-center">
                                        <div>
                                            {exporting ?
                                                <CircularProgress size={30} /> :
                                                <Button variant="contained" color="primary" onClick={exportRecords}>
                                                    <ExportIcon className="mr-2"/> Export
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="mb-5">
                            <CardContent>
                                <div className="flex">
                                    <div>
                                        <div className="admin-query-report-label">
                                            Total # Locations
                                        </div>
                                        <div className="mt-3">
                                            {filteredLocations.length}
                                        </div>
                                    </div>
                                    <div className="ml-8">
                                        <div className="admin-query-report-label">
                                            # Claimed Locations
                                        </div>
                                        <div className="mt-3">
                                            {filteredLocations.reduce((total, location) => total + (location.claimed ? 1 : 0), 0)}
                                        </div>
                                    </div>
                                    <div className="ml-8">
                                        <div className="admin-query-report-label">
                                            # Claimed Chairs
                                        </div>
                                        <div className="mt-3">
                                            {filteredLocations.reduce((total, location) => total + (location.claimed ? location.numberOfChairs : 0), 0)}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="specialties">
                                    <div className="flex w-full">
                                        <div className="flex items-center admin-query-report-section-label flex-1">
                                            Specialties
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setSpecialtyIds(Object.keys(specialtyIds).reduce((ret, specialtyId) => {
                                                    ret[specialtyId] = true;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Select All
                                            </Button>
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setSpecialtyIds(Object.keys(specialtyIds).reduce((ret, specialtyId) => {
                                                    ret[specialtyId] = false;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Unselect All
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="admin-location-report-specialties-card">
                                        {sortedSpecialties.map(specialty => {
                                            return specialty ? (
                                                <div key={specialty.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="specialty"
                                                                color="primary"
                                                                checked={specialtyIds[specialty.id] || false}
                                                                onChange={event => {
                                                                    setSpecialtyIds({
                                                                        ...specialtyIds,
                                                                        [specialty.id]: event.target.checked
                                                                    });
                                                                }}  />
                                                        }
                                                        label={`${specialty.name} (${specialty.numSearches})`}
                                                    />
                                                </div>
                                            ) : null})}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="medicationsAvailable">
                                    <div className="flex w-full">
                                        <div className="flex items-center admin-location-report-section-label flex-1">
                                            Medications Available
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setMedicationIds(Object.keys(medicationIds).reduce((ret, medicationId) => {
                                                    ret[medicationId] = true;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Select All
                                            </Button>
                                        </div>
                                        <div className="ml-5">
                                            <Button variant="text" onClick={event => {
                                                setMedicationIds(Object.keys(medicationIds).reduce((ret, medicationId) => {
                                                    ret[medicationId] = false;
                                                    return ret;
                                                }, {}));
                                                event.stopPropagation();
                                            }}>
                                                Unselect All
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="admin-location-report-specialties-card">
                                        {sortedMedicationsAvailable.map(medication => {
                                            return medication ? (
                                                <div key={medication.id}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                name="medication"
                                                                color="primary"
                                                                checked={medicationIds[medication.id] || false}
                                                                onChange={event => {
                                                                    setMedicationIds({
                                                                        ...medicationIds,
                                                                        [medication.id]: event.target.checked
                                                                    });
                                                                }}  />
                                                        }
                                                        label={`${medication.name} (${medication.numSearches})`}
                                                    />
                                                </div>
                                            ) : null})}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className="flex-1 relative admin-location-report-map-container">
                        <GoogleHeatMap data={filteredLocations} onMap={map => setMap(map)}  />
                    </div>
                </div>
            }
        </div>
    )
};

function _getSpecialtyById(specialtyId, specialties) {
    return specialties.find(specialty => specialty.id === specialtyId);
}

function _getMedicationById(medicationId, medicationsAvailable) {
    return medicationsAvailable.find(medication => medication.id === medicationId);
}

export default AdminLocationReport;
