import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import NicaApi from '@devsontap/nica-api';

function initFirebase() {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    });
    // KBM - For testing
    // firebase.functions().useEmulator("localhost", 5003);
}

export function isLocal() {
    return document.domain === "localhost";
}

initFirebase();

const api = new NicaApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

api.loginWithEmailPassword = (email, password) => {
    if (email && password) {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }
    return false;
};

api.initiatePasswordReset = (email) => {
    if (email) {
        return firebase.auth().sendPasswordResetEmail(email);
    }
    return false;
};

api.loginWithToken = (token) => {
    return firebase.auth().signInWithCustomToken(token);
};

api.logout = () => {
    console.log("IM SIGNING OUT NOW!!");
    return firebase.auth().signOut();
};

api.uploadFile = async (file, uid) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(uid + "." + extension);

    const snapshot = await fileRef.put(file);
    const downloadUrl = await snapshot.ref.getDownloadURL();
    return downloadUrl;
};

api.exportRecords = (locationIds, exportFolderId, templateId, sheetName) => {
    return firebase.functions().httpsCallable("export-locationsToGoogleSheet")({
        locationIds,
        exportFolderId,
        templateId,
        sheetName
    });
};

api.getSalesTaxReport = (startDate, endDate) => {
    return firebase.functions().httpsCallable("purchases-getSalesTaxReport")({
        startDate,
        endDate
    }) 
}

export default api;
