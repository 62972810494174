import React, {useState} from 'react';
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import {loadOrganizationContacts} from "../../../../redux/organizations/actions";
import {loadLocationContacts} from "../../../../redux/locations";

import './index.css';

const MembershipListItem = ({ activePurchasable }) => {
    const [locationContactsLoading, setLocationContactsLoading] = useState(false);
    const [orgContactsLoading, setOrgContactsLoading] = useState(false);
    const [locationContacts, setLocationContacts] = useState(null);
    const [orgContacts, setOrgContacts] = useState(null);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const onLoadOrgContacts = () => {
        setOrgContactsLoading(true);
        dispatch(loadOrganizationContacts(activePurchasable.orgId, setOrgContactsLoading, setOrgContacts, enqueueSnackbar));
    };

    const onLoadLocationContacts = () => {
        setLocationContactsLoading(true);
        dispatch(loadLocationContacts(activePurchasable.location.id, setLocationContactsLoading, setLocationContacts, enqueueSnackbar));
    };

    return (
        <>
            <TableCell>
                {activePurchasable.contactInfo.firstName} {activePurchasable.contactInfo.lastName} ({activePurchasable.contactInfo.title})
            </TableCell>
            <TableCell>
                {activePurchasable.location ?
                    <>
                        <div>
                            {activePurchasable.location.name}
                        </div>
                        <div>
                            {activePurchasable.location.address.line1}
                        </div>
                        <div>
                            {activePurchasable.location.address.city}, {activePurchasable.location.address.stateCode} {activePurchasable.location.address.zip}
                        </div>
                    </>
                    :
                    "N/A"
                }
            </TableCell>
            <TableCell>
                {activePurchasable.location ?
                    <>
                        {locationContactsLoading ?
                            <CircularProgress size={25} />
                            :
                            <>
                                {locationContacts ?
                                    locationContacts.map((contact, index) => (
                                        <div key={`${activePurchasable.orgId}-location-contact-${index}`}>
                                            {contact}
                                        </div>
                                    ))
                                    :
                                    <IconButton onClick={onLoadLocationContacts}>
                                        <VisibilityIcon />
                                    </IconButton>
                                }
                            </>
                        }
                    </>
                    :
                    "N/A"
                }
            </TableCell>
            <TableCell>
                {orgContactsLoading ?
                    <CircularProgress size={25} />
                    :
                    <>
                        {orgContacts ?
                            orgContacts.map((contact, index) => (
                                <div key={`${activePurchasable.orgId}-contact-${index}`}>
                                    {contact}
                                </div>
                            ))
                            :
                            <IconButton onClick={onLoadOrgContacts}>
                                <VisibilityIcon />
                            </IconButton>
                        }
                    </>
                }
            </TableCell>
        </>
    )
};

export default MembershipListItem;
