import React, {useState} from 'react';
import {Formik, Form} from "formik";
import {object, string} from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";

import CustomFormikField from "../../_common/formik/customField";
import {initiatePasswordReset} from "../../../redux/login";
import {useLoginSelector} from "../../../redux/login/selectors";

import './index.scss';

const ForgotPassword = ({ setShowForgotPassword }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {loggingIn} = useLoginSelector();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <div>
            <div className="lato login-title">
                Forgot Password
            </div>
            {!showConfirmation ?
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={object().shape({
                        email: string().email("Please enter a valid email").required()
                    })}
                    onSubmit={({email}) => {
                        dispatch(initiatePasswordReset(email, () => setShowConfirmation(true), enqueueSnackbar));
                    }}>
                    <Form>
                        <div className="login-form-row">
                            Enter your email address below to initiate a Password Reset. You will be emailed a link to reset
                            your password.
                        </div>
                        <div className="login-form-row">
                            <CustomFormikField
                                name="email"
                                label="Email"
                                placeholder="john.smith@gmail.com"
                                CustomComponent={TextField}
                                fullWidth />
                        </div>
                        {loggingIn ?
                            <div className="flex justify-center items-center">
                                <CircularProgress size={35} />
                            </div> :
                            <>
                                <div className="login-form-row mt-8 flex justify-center items-center">
                                    <Button variant="contained" color="primary" type="Submit" fullWidth>
                                        Initiate Reset
                                    </Button>
                                </div>
                                <div className="flex justify-center items-center">
                                    <Button variant="text" onClick={() => setShowForgotPassword(false)}>
                                        Cancel
                                    </Button>
                                </div>
                            </>
                        }
                    </Form>
                </Formik> :
                <div>
                    <div className="login-form-row">
                        You have been emailed a link to complete the Password Reset.
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => setShowForgotPassword(false)} fullWidth>
                            Got it
                        </Button>
                    </div>
                </div>
            }

        </div>
    );
};

export default ForgotPassword;
