import React, {useState, useMemo, useEffect} from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import ReportIcon from '@mui/icons-material/TableChart';
import AdminQueryReportIcon from '@mui/icons-material/ImageSearch';
import MapIcon from '@mui/icons-material/Map';

import AdminOnly from '../_common/adminOnly';
import {signOut} from "../../redux/login";
import {reportsRestrictionsSelector} from "../../redux/login/selectors";

import logo from '../../img/nica-logo.svg';

import './index.css';

const items = [
    { label: 'Location Report', path: '/location-report', Icon: MapIcon, adminOnly: false },
    { label: 'Query Report', path: '/query-report', Icon: AdminQueryReportIcon, adminOnly: false },
    { label: 'Membership Report', path: '/membership-report', Icon: ReportIcon, adminOnly: true },
    { label: 'Purchasables Report', path: '/purchasables-report', Icon: ReportIcon, adminOnly: true },
    { label: 'Sales Tax Report', path: "/sales-tax-report", Icon: ReportIcon, adminOnly: true },
    { label: 'Whitelabel Report', path: "/whitelabel-report", Icon: ReportIcon, adminOnly: false, hidden: true },
];

const Nav = () => {
    const [navItems, setNavItems] = useState(items);
    const dispatch = useDispatch();
    const reportsRestrictions = useSelector(reportsRestrictionsSelector);

    const visibleNavItems = useMemo(() => {
        return navItems.filter(item => !item.hidden);
    }, [navItems]);

    useEffect(() => {
        if (reportsRestrictions?.whitelabelReportUrl) {
            setNavItems(items => {
                const lastItem = items[items.length - 1];

                return items.slice(0, items.length - 1).concat([{
                    ...lastItem,
                    hidden: false,
                }]);
            });
        }
    }, [reportsRestrictions]);

    return (
        <div className="nav-container">
            <div className="nav-header">
                <div className="flex justify-end">
                    <Button variant="text" onClick={() => dispatch(signOut())}>
                        Log Out
                    </Button>
                </div>
                <div>
                    <img src={logo} className="nav-header-logo" alt="nica logo" />
                </div>
                <div className="nav-header-title oswald">
                    Reports
                </div>
            </div>
            <div className="admin-nav-card-items">
                <List component="nav">
                    {visibleNavItems.map(({ path, label, Icon, adminOnly }, index) => {
                        if (adminOnly) {
                            return (
                                <AdminOnly key={`nav-item-${label}`} noRedirect={true}>
                                    <NavLink to={path} className="nav-item-text" activeClassName="nav-item-text-selected">
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Switch>
                                                    <Route path={path} render={() => <Icon htmlColor="#1c5c7f" />} />
                                                    <Route render={() => <Icon htmlColor="#A0A0A0" />} />
                                                </Switch>
                                            </ListItemIcon>
                                            <div className="nav-item-label flex items-center">
                                                {label}
                                            </div>
                                        </ListItem>
                                    </NavLink>
                                </AdminOnly>
                            )
                        }
                        return (
                            <NavLink to={path} className="nav-item-text" activeClassName="nav-item-text-selected" key={`nav-item-${label}`}>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Switch>
                                            <Route path={path} render={() => <Icon htmlColor="#1c5c7f" />} />
                                            <Route render={() => <Icon htmlColor="#A0A0A0" />} />
                                        </Switch>
                                    </ListItemIcon>
                                    <div className="nav-item-label flex items-center">
                                        {label}
                                    </div>
                                </ListItem>
                            </NavLink>
                        );
                    })}
                </List>
            </div>
        </div>
    )

};

export default Nav;
