import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    reportLoading: false,
    report: null,
};

const salesTaxReportSlice = createSlice({
    name: 'salesTaxReport',
    initialState: INITIAL_STATE,
    reducers: {
        loading(state, action) {
            state.reportLoading = action.payload;
        },
        success(state, action) {
            state.reportLoading = false;
            state.report = action.payload;
        },
    }
});

export const { loading, success, pendingCheckPaymentsSuccess } = salesTaxReportSlice.actions;

export default salesTaxReportSlice.reducer;

// CUSTOM THUNK ACTIONS

export const loadSalesTaxReport = (startDate, endDate, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        dispatch(loading(true));
        return api.getSalesTaxReport(startDate, endDate)
            .then(report => {
                console.log("Sales Tax Report", report);
                return dispatch(success(report));
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
                dispatch(loading(false));
            })
    }
);
