import {
    MEMBERSHIPS_LOADING,
    MEMBERSHIPS_SUCCESS,
    MEMBERSHIPS_ERROR
} from "./types";

const INITIAL_STATE = {
    membershipsLoading: true,
    memberships: [],
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MEMBERSHIPS_LOADING:
            return {
                ...state,
                membershipsLoading: true,
            };
        case MEMBERSHIPS_SUCCESS:
            return {
                ...state,
                membershipsLoading: false,
                memberships: action.payload
            };
        case MEMBERSHIPS_ERROR:
            return {
                ...state,
                membershipsLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;