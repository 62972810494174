import React from 'react';
import {useSelector} from "react-redux";
import {reportsRestrictionsSelector} from "../../redux/login/selectors";

const WhitelabelReport = () => {
    const reportsRestrictions = useSelector(reportsRestrictionsSelector);

    console.log("WhitelabelReport reportsRestrictions:", reportsRestrictions);

    return reportsRestrictions && (
        <div className="app-page relative">
            <div className="absolute inset-0">
                <iframe title="Whitelabel Report"
                        width="100%"
                        height="100%"
                        src={reportsRestrictions?.whitelabelReportUrl}
                        frameBorder="0"
                        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"/>
            </div>
        </div>
    )
};

export default WhitelabelReport;
