import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loading: true,
    locationLoading: false,
    locationReportLoading: true,
    locationIds: null,
    locationMap: {},
    reportMap: {},
    locationError: null,
    filter: null
};

const discountCodesSlice = createSlice({
    name: 'discountCodes',
    initialState: INITIAL_STATE,
    reducers: {
        locationsLoading(state, action) {
            state.loading = action.payload;
        },
        locationsSuccess(state, action) {
            state.loading = false;
            state.locationMap = action.payload.reduce((ret, location) => {
                ret[location.id] = location;
                return ret;
            }, state.locationMap);
            state.locationIds = Object.keys(state.locationMap);
        },
        locationsError(state, action) {
            state.loading = false;
        },
        locationReportLoading(state, action) {
            state.locationReportLoading = true;
        },
        locationReportSuccess(state, action) {
            state.locationReportLoading = false;
            state.reportMap[action.payload.locationId] = action.payload.results;
        },
        locationReportError(state, action) {
            state.locationReportLoading = false;
        },
        locationLoading(state, action) {
            state.locationLoading = true;
        },
        locationSuccess(state, action) {
            state.locationLoading = false;
            state.locationMap[action.payload.id] = action.payload;
            state.locationIds = Object.keys(state.locationMap);
        },
        locationError(state, action) {
            state.locationLoading = false;
        },
        locationDelete(state, action) {
            delete state.locationMap[action.payload];
            state.locationIds = Object.keys(state.locationMap);
        }
    }
});

export const {
    locationsLoading, locationsSuccess, locationsError, locationReportLoading, locationReportSuccess, locationReportError,
    locationLoading, locationSuccess, locationError, locationDelete
} = discountCodesSlice.actions;

export default discountCodesSlice.reducer;

/**
 * Custom Thunk Actions
 */

export const loadAdminLocationReport = (setLoading, setReport, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.getAdminLocationReport()
            .then(report => {
                setLoading(false);
                setReport(report);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
);

export const loadLocationContacts = (locationId, setLoading, setContacts, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        return api.getLocation(locationId)
            .then(location => {
                setLoading(false);
                setContacts([
                    location.phoneNumber,
                    ...(location.users || [])
                ]);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
            })
    }
);

export const exportLocationReportRecords = (locationIds, onComplete, enqueueSnackbar) => (
    (dispatch, getState, api) => {
        const adminTemplate = "1Xli1-RhAsEFTlGs63hoNmpOkj-Zz1vDyIrjcU-Z9CE8";
        const userTemplate = "1hSgnwcVOYjPMWOYDrF2oNmfHMhgzl6YEd1vxYEThohE";

        const admin = getState().login.claims.admin;

        return api.exportRecords(locationIds, "1EK_9Ptf5hyMeta1-tFwhKcOeTaw7ofyK", admin ? adminTemplate : userTemplate, "LocationReport")
            .then(response => {
                if (onComplete) {
                    onComplete(response.data);
                }
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, { variant: "error" });
                if (onComplete) {
                    onComplete();
                }
            })
    }
);
