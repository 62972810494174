import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";

import {impersonateUser} from "../../redux/login";

import './index.css';

const AdminUserImpersonate = ({onClose}) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <div className="flex">
            <div className="flex-1">
                <TextField
                    name="email"
                    label="User's Email"
                    placeholder="john.smith@gmail.com"
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    fullWidth />
            </div>
            <div className="flex items-end ml-4">
                {loading ?
                    <CircularProgress size={45} className="ml-3" />
                    :
                    <Button variant="text" color="primary" disabled={email.length < 5} onClick={() => {
                        setLoading(true);
                        dispatch(impersonateUser(email, setLoading, onClose, enqueueSnackbar));
                    }}>
                        Submit
                    </Button>
                }
            </div>
        </div>
    )
};

export default AdminUserImpersonate;
