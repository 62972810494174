import React from 'react';
import { getDefaultMiddleware, configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import 'date-fns';

import api from './api';
import rootReducer from './redux';
import theme from './materialUI/theme';
import AuthListener from "./components/authListener";
import Login from "./components/login";
import Home from './components/home';
import ErrorBoundary from "./components/_common/errorBoundary";

import './css/App.css';
// import './css/atomic.css';

/**
 * Create a Redux store and configure extra argument for Thunk middleware
 */
const customizedMiddleware = getDefaultMiddleware({
    thunk: {
        extraArgument: api
    },
});

const store = configureStore({
    reducer: rootReducer,
    middleware: customizedMiddleware
});

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Provider store={store}>
                        <div className="App">
                            <ErrorBoundary>
                                <AuthListener unAuthed={<Login />}>
                                    <Home />
                                </AuthListener>
                            </ErrorBoundary>
                        </div>
                    </Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </SnackbarProvider>
    );
};

export default App;
