import {useShallowEqualSelector} from "../index";

export const reportsRestrictionsSelector = ({ login }) => login.reportsRestrictions;

/**
 * Returns the login data
 */
export const useLoginSelector = () => {
    return useShallowEqualSelector(({ login: {authEmail, loggedIn, claims, orgIds, loggingIn } }) => {
        return {
            authEmail,
            loggedIn,
            claims,
            orgIds,
            loggingIn,
            numOrgIds: orgIds ? orgIds.length : 0
        }
    });
};