import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import attributes from './attributes';
import locations from './locations';
import login from './login';
import memberships from './memberships';
import queries from './queries';
import membershipReport from './membershipReport';
import organizations from './organizations';
import purchasables from './purchasables';
import purchasablesReport from './purchasablesReport';
import salesTaxReport from './salesTaxReport';

/**
 * Main Redux reducer
 */
const RootReducer = combineReducers({
    attributes,
    locations,
    login,
    membershipReport,
    memberships,
    queries,
    organizations,
    purchasables,
    purchasablesReport,
    salesTaxReport,
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;
